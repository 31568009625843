















































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { PaginatedList } from "@/api/models/common/paginatedList";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "create-membre",
})
export default class AjoutMembre extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewGroupeSidebarActive!: boolean;
  loading = false;

  required = required;

  userSelected: any = null

  listMembres: any[] = [];
  totalCountMembre: any = null

  async created() {
    await this.listUsers({})
  }

  resetForm() {
    this.userSelected = null;
  }

  async listUsers(params: any){
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        false,
        "Nom",
        false
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listMembres = this.listMembres!.concat(
            response.items.filter(
              (ri) => !this.listMembres!.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.totalCountMembre = response.totalCount
        }
      )
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.groupes
      .addMembre(this.$route.params.id, this.userSelected.id)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Ajout d'un membre",
            text: "Membre ajouté avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        await this.$emit("refresh-groupes");
        this.$emit("update:is-add-new-groupe-sidebar-active", false);
        this.loading = false;
      });
  }
}
