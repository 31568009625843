


















































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Groupe } from "@/api/models/groupes/groupe";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import AjoutMembre from "./AjoutMembre.vue"
import { MembreGroupes } from "@/api/models/membreGroupes/membreGroupes";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BSpinner,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
   AjoutMembre
  },
})
export default class ListMembresGroupe extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  groupeSelected: Groupe | null = null;
  mainLoading = true;

  isAddNewMembreSidebarActive = false;

  tableColumns = [
    { key: "nom", sortable: true },
    { key: "prenom", sortable: true },
    { key: "email", sortable: true },
    { key: "telephone", sortable: true },
    { key: "actions", sortable: false }
  ];
  paginatedListMembres = new PaginatedList<MembreGroupes>();

  async created() {
    this.mainLoading = true;
    await this.$http.myressif.groupes
      .getById(this.$route.params.id)
      .then((response: Groupe) => {
        this.groupeSelected = response;
      });

    await this.loadListMembres({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
    this.mainLoading = false;
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListMembres.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListMembres.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListMembres({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  goToList() {
    this.$router.push({ name: "groupes" });
  }

  async loadListMembres(params: any) {
    if (!params || params?.reset) {
      this.paginatedListMembres = new PaginatedList<MembreGroupes>();
    }
    await this.$http.myressif.groupes
      .listMembres(this.$route.params.id, params?.pageNumber ?? this.currentPage, params?.pageSize ?? this.perPage)
      .then((response: PaginatedList<MembreGroupes>) => {
        this.paginatedListMembres = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce membre ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.groupes
      .removeMembre(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un membre",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        await this.loadListMembres({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }
}
